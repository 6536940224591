import { useEffect, useState } from "react"
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from "./Footer";
import AppBar from "./AppBar";
import SessionContext from "./SessionContext";
import { Outlet, useParams, useSearchParams } from "react-router-dom";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#5567FF"
        }
    },
    typography: {
        fontFamily: [
            '"Segoe UI"',
            'sans-serif',
            'Roboto',
            'Arial',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Helvetica Neue"',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});


export default function Layout() {
    const { remote } = useParams();
    const [searchParams] = useSearchParams({ismirror: false})
    const [remoteInfo, setRemoteInfo] = useState({
        branch: {
            company: {
                logo: "https://specularo.com/dark.png"
            }
        },
        isMirror: false
    })

    useEffect(() => {
        if(searchParams.get("ismirror") === true || searchParams.get("ismirror") === "true") {
            defaultTheme.palette.mode = "dark"
            defaultTheme.palette.background.default = "#000"
        }
        if(searchParams.get("isservice") === true || searchParams.get("isservice") === "true") {
            setRemoteInfo({branch: {company: {logo: "66c99d08cb8609b095685cf5"}}})
        } else {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/assign`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mirror: {
                        token: remote
                    }
                })
            })
                .then(res => res.json())
                .then(res => {
                    setRemoteInfo({...res, isMirror: searchParams.get("ismirror")})
                })
                .catch(err => {
                    console.error(err)
                })
        }
        /*fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/find_parking?type=electric&floorPreference=-1`)
            .then(res => res.json())
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.error(err)
            })*/
        console.log("aa")
        
    }, [])

    return (
        <SessionContext.Provider value={{...remoteInfo, setInfo: setRemoteInfo}}>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <AppBar visible={!remoteInfo.isMirror || remoteInfo.isMirror === "false"} />
                <div className="box">
                    <Outlet />
                </div>
                <Footer visible={!remoteInfo.isMirror || remoteInfo.isMirror === "false"} />
            </ThemeProvider>
        </SessionContext.Provider>
    )
}