import { useContext } from "react"
import SessionContext from "../components/SessionContext"

export default function Main() {
    const remoteInfo = useContext(SessionContext)
    return (
        <section>
            <h1>Specularo Front</h1>
            {
            Object.keys(remoteInfo).length > 0 &&
            <img src={`${remoteInfo?.branch?.company?.logo}`} alt="" />
            }

            <div className="item">
            You should get a direct link to the relevant page.
            </div>
        </section>
    )
}