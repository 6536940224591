import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SessionContext from "../components/SessionContext";


export default function ServicePage() {
    const { serviceId, servicelisting } = useParams();
    const [contactInfo, setContactInfo] = useState({})
    const layoutContext = useContext(SessionContext)

    useEffect(() => {
        console.log(serviceId)
        console.log(servicelisting)

        fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_firebase_info`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serviceId: serviceId,
                listingId: servicelisting
            })
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            setContactInfo(res)
            if(res.guestApp) {
                layoutContext.setInfo({...layoutContext, guestApp: res.guestApp})
            }
        })
    }, [])

    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = contactInfo.photos[0];
        link.download = 'מזכרת מהאירוע.jpg'; // Default name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <section>
            {layoutContext.guestApp && layoutContext.guestApp.topText &&
            <div dir="rtl" dangerouslySetInnerHTML={{__html: layoutContext.guestApp.topText}} style={{width: "100%"}}>
            
            </div>
            }
            <img src={contactInfo.photos ? contactInfo.photos[0] : ""} />

            {layoutContext.guestApp && layoutContext.guestApp.bottomText &&
            <div dir="rtl" dangerouslySetInnerHTML={{__html: layoutContext.guestApp.bottomText}} style={{width: "100%"}}>
                
            </div>
            }
            <button className="buttonCss" onClick={downloadImage} dir="rtl">
                הורידו את התמונה!
            </button>
        </section>
    )
}